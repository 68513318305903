import { Injectable, NgZone } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { State, Action, StateContext, Store } from '@ngxs/store';
import { Device, DeviceInfo } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';

import { ClientMenuStateModel } from './client-menu.model';
import {
  SetActivePackage,
  AddChangeToActiveSubscription,
  ClearChangesInActiveSubscription,
  RemoveChangeFromActiveSubscription,
  LoadSubscriptions,
  LoadActiveSubscription,
  LoadReplacementDishes,
  ClearClientMenuData,
  ClearActiveSubscriptionData,
  UpdateDishFeedback,
  DeleteSubscription,
  LoadNotifications,
  MarkAsRead,
} from './client-menu.actions';
import { ClientSubscriptionsApiService, PushService, RudderStackService, StorageService, SubscriptionService } from '@shared/services';
import { removeDishFromChanges, removeDishFromSubscription, replaceDishInActiveSubscription } from './client-menu.helpers';
import { ClientMenuDish } from '@modules/client-menu/models';
import { ClientMenuApiService } from '@modules/client-menu/services';
import { CommonEvents, LoadingTagEnum, SubscriptionStatusEnum } from '@shared/enums';
import dayjs from 'dayjs';
import { InitProgram, SetPromocode, UpdateProgramPrice } from '@store/program';
import { CurrentProgram, Notification } from '@shared/models';
import { deepClone, formatToDuration } from '@shared/utils';
import { ClientMenuEventsEnum } from '@modules/client-menu/enums';
import { SetSubscriptionCreationResp } from '@store/payment';
import { SetLoading, UnsetLoading } from '@store/loading';

const initialState = {
  activeSubscriptionDetails: null,
  subscriptionList: [],
  areSubscriptionsLoaded: true,
  activePackage: null,
  changedDish: {},
  dishesMap: {},
  replacementDishes: [],
  isSubscriptionListLoading: false,
  isMenuLoading: false,
  isCalendarLoading: false,
  isSubscriptionDetailsLoading: false,
  didPageFail: false,
  isIngredientsExclusionEnabled: false,
  isDialogIngredientsExclusionEnabled: true,
  ingredientsList: [],
  notifications: [],
};

@State<ClientMenuStateModel>({
  name: 'clientMenu',
  defaults: initialState,
})
@Injectable()
export class ClientMenuState {
  constructor(
    private store: Store,
    private router: Router,
    private zone: NgZone,
    private apiService: ClientSubscriptionsApiService,
    private clientService: ClientMenuApiService,
    private subscriptionService: SubscriptionService,
    private storageService: StorageService,
    private rudderstack: RudderStackService,
    private pushService: PushService,
  ) {}

  @Action(LoadActiveSubscription)
  loadActiveSubscription(
    { patchState, getState, dispatch }: StateContext<ClientMenuStateModel>,
    { subscriptionId, options }: LoadActiveSubscription,
  ) {
    const { activeSubscriptionDetails } = getState();

    // Если изменилась только дата пакета, а Id подписки тоже, то меняем только активный пакет!
    if (options?.date && activeSubscriptionDetails?.subscriptionId === subscriptionId) {
      dispatch(new SetActivePackage(options.date));

      return;
    }

    let id = subscriptionId;

    if (!id) {
      const { subscriptionList } = getState();
      id = subscriptionList?.[0]?.subscriptionId;
    }

    patchState({
      isSubscriptionDetailsLoading: true,
      activeSubscriptionDetails: null,
      activePackage: null,
      isMenuLoading: true,
    });

    return this.apiService.getSubscriptionDetails(id).pipe(
      delay(100),
      tap(activeSubscriptionDetails => {
        patchState({
          activeSubscriptionDetails,
          changedDish: {},
          isSubscriptionDetailsLoading: false,
          isMenuLoading: false,
          didPageFail: false,
        });

        this.storageService.getChangeDishesToStorage(id).then(diffs => {
          if (diffs?.length) {
            diffs.forEach(({ packageId, originalDish, newDish }) =>
              this.store.dispatch(new AddChangeToActiveSubscription(packageId, originalDish, newDish, true)),
            );
          }
        });

        const programParams: Partial<CurrentProgram> = {
          dailyCalories: activeSubscriptionDetails.size,
          durationId: formatToDuration(activeSubscriptionDetails.feedDaysCount),
        };

        dispatch([new SetActivePackage(options?.date), new InitProgram(programParams)]);

        if (options?.promocode) {
          dispatch([
            new SetPromocode(options.promocode, 'fromUrl'),
            new SetPromocode(options.promocode, 'fromInput'),
            new UpdateProgramPrice(options.promocode),
          ]);
        }
      }),
    );
  }

  @Action(ClearActiveSubscriptionData)
  clearActiveSubscriptionData({ patchState }: StateContext<ClientMenuStateModel>): void {
    patchState({
      activeSubscriptionDetails: null,
      activePackage: null,
    });
  }

  @Action(SetActivePackage)
  setActivePackage({ getState, patchState }: StateContext<ClientMenuStateModel>, { date }: SetActivePackage): void {
    const { activeSubscriptionDetails } = getState();
    const currentDate = date ? this.convertDate(date) : new Date();
    const activePackage = activeSubscriptionDetails.packageItems.find(item => {
      return dayjs(currentDate).isBefore(dayjs(item.date), 'day') || dayjs(currentDate).isSame(dayjs(item.date), 'day');
    });

    patchState({ activePackage });
  }

  /**
   *
   * @param activeDate дата формата 22-03-2023 2023-03-22
   * @returns дата формата 2023-03-22
   */
  private convertDate(activeDate: string): string {
    return activeDate.split('-').reverse().join('-');
  }

  @Action(AddChangeToActiveSubscription)
  addChangeToActiveSubscription(
    ctx: StateContext<ClientMenuStateModel>,
    { packageId, originalDish, newDish, notUseStorage }: AddChangeToActiveSubscription,
  ): void {
    let replacingDish: ClientMenuDish;
    if (originalDish !== null) {
      replacingDish = {
        ...newDish,
        mealType: originalDish.mealType,
        shouldBeConfirmed: true,
        originalUuid: originalDish.id,
      };
    } else {
      replacingDish = {
        ...newDish,
        shouldBeConfirmed: true,
        isAdded: true,
      };
    }

    replaceDishInActiveSubscription(ctx, originalDish, replacingDish);

    const { activeSubscriptionDetails, changedDish } = ctx.getState();
    const replacementDiffDish = { packageId, originalDish, newDish: replacingDish };

    if (!changedDish[activeSubscriptionDetails.subscriptionId]) {
      if (!notUseStorage) {
        this.storageService.setChangeDishesToStorage(activeSubscriptionDetails.subscriptionId, [replacementDiffDish]);
      }

      ctx.patchState({
        changedDish: {
          ...changedDish,
          [activeSubscriptionDetails.subscriptionId]: [replacementDiffDish],
        },
        replacementDishes: [],
      });
    } else {
      if (!notUseStorage) {
        this.storageService.addReplacementDishesToStorage(activeSubscriptionDetails.subscriptionId, replacementDiffDish);
      }

      const currentDiffs = changedDish[activeSubscriptionDetails.subscriptionId];
      ctx.patchState({
        changedDish: {
          ...changedDish,
          [activeSubscriptionDetails.subscriptionId]: [...currentDiffs, replacementDiffDish],
        },
        replacementDishes: [],
      });
    }
  }

  @Action(RemoveChangeFromActiveSubscription)
  async removeChangeFromActiveSubscription(
    ctx: StateContext<ClientMenuStateModel>,
    { dishToRemove }: RemoveChangeFromActiveSubscription,
  ): Promise<void> {
    const { activeSubscriptionDetails, changedDish, activePackage } = ctx.getState();

    const { subscriptionId } = activeSubscriptionDetails;
    if (!activeSubscriptionDetails && !activePackage) {
      return;
    }

    const changedDishesFromStore = JSON.parse((await Preferences.get({ key: subscriptionId })).value);
    const updatedChange = removeDishFromChanges(ctx, dishToRemove);
    const updatedSubscription = removeDishFromSubscription(ctx, changedDishesFromStore, dishToRemove);

    this.storageService.setChangeDishesToStorage(subscriptionId, updatedChange);

    ctx.patchState({
      activeSubscriptionDetails: updatedSubscription,
      changedDish: {
        ...changedDish,
        [subscriptionId]: updatedChange,
      },
    });
  }

  @Action(ClearChangesInActiveSubscription)
  clearChangesInActiveSubscription(
    { getState, dispatch }: StateContext<ClientMenuStateModel>,
    { isPaymentSuccess }: ClearChangesInActiveSubscription,
  ): Observable<any> {
    const { activeSubscriptionDetails, changedDish } = getState();

    if (!activeSubscriptionDetails) {
      return;
    }

    const { subscriptionId, unfinishedPaymentId } = activeSubscriptionDetails;

    // Заменяемые блюда в конкретной подписке
    const changedDishesBySubscription = changedDish[subscriptionId];

    if (changedDishesBySubscription) {
      changedDishesBySubscription.forEach(({ packageId, newDish }) => {
        dispatch(new RemoveChangeFromActiveSubscription(packageId, newDish));
      });
    }

    if (unfinishedPaymentId && !isPaymentSuccess) {
      return this.clientService.cancelCustomization(unfinishedPaymentId).pipe(
        delay(500),
        tap(() => dispatch(new LoadActiveSubscription(subscriptionId))),
      );
    }

    return EMPTY;
  }

  @Action(LoadReplacementDishes)
  loadReplacementDishes(
    { patchState }: StateContext<ClientMenuStateModel>,
    { packageId, dishId }: LoadReplacementDishes,
  ): Observable<ClientMenuDish[]> {
    if (!packageId && !dishId) {
      patchState({
        replacementDishes: [],
      });

      return;
    }

    return this.clientService.getReplacementDishes(packageId, dishId).pipe(tap(replacementDishes => patchState({ replacementDishes })));
  }

  @Action(LoadSubscriptions)
  loadSubscriptions({ patchState }: StateContext<ClientMenuStateModel>) {
    patchState({
      isMenuLoading: true,
      isSubscriptionListLoading: true,
      areSubscriptionsLoaded: true,
    });

    return this.apiService.getAll().pipe(
      tap(list => {
        const activeList = list.filter(({ status }) => status !== SubscriptionStatusEnum.Past);

        patchState({
          subscriptionList: activeList,
          isMenuLoading: false,
          isCalendarLoading: true,
          isSubscriptionDetailsLoading: true,
          isSubscriptionListLoading: false,
          areSubscriptionsLoaded: false,
        });

        if (!activeList.length) {
          this.rudderstack.track(ClientMenuEventsEnum.noSubscriptions);

          this.zone.run(() => this.router.navigate(['cabinet', 'create']));
          return;
        }

        this.rudderstack.track(CommonEvents.showSubscriptionsList);
      }),
    );
  }

  @Action(DeleteSubscription)
  deleteSubscription({ dispatch }: StateContext<ClientMenuStateModel>, { subscriptionId }: DeleteSubscription): Observable<void> {
    if (!subscriptionId) {
      return;
    }

    return this.subscriptionService.deleteSubscription(subscriptionId).pipe(
      tap(async () => {
        dispatch(new SetSubscriptionCreationResp(null));

        const deviceInfo: DeviceInfo = await Device?.getInfo();
        const platform = deviceInfo?.platform;

        if (platform === 'ios') {
          this.router.navigate(['cabinet', 'create', 'program']);
          window.location.reload();
        }
      }),
    );
  }

  @Action(ClearClientMenuData)
  clearClientMenuData({ patchState }: StateContext<ClientMenuStateModel>) {
    patchState(initialState);
  }

  @Action(UpdateDishFeedback)
  updateDishFeedback({ getState, patchState }: StateContext<ClientMenuStateModel>, { dish, feedback }: UpdateDishFeedback) {
    const { activeSubscriptionDetails, activePackage } = getState();
    const changedSubscription = deepClone(activeSubscriptionDetails);
    const currentPackage = deepClone(activePackage);
    const packageToUpdate = changedSubscription.packageItems.map(packageItem =>
      packageItem.packageId === currentPackage.packageId ? currentPackage : packageItem,
    );

    currentPackage.menuItems = currentPackage.menuItems.map(item => {
      if (item.id === dish.id) {
        return {
          ...item,
          dishFeedback: feedback,
        };
      }
      return item;
    });
    changedSubscription.packageItems = packageToUpdate;

    patchState({
      activeSubscriptionDetails: changedSubscription,
      activePackage: currentPackage,
    });
  }

  @Action(LoadNotifications)
  loadNotifications({ patchState, dispatch }: StateContext<ClientMenuStateModel>) {
    dispatch(new SetLoading(LoadingTagEnum.loadNotifications));

    return this.pushService.getNotifications().pipe(
      tap((notifications: Notification[]) => {
        patchState({ notifications });

        dispatch(new UnsetLoading(LoadingTagEnum.loadNotifications));
      }),
    );
  }

  @Action(MarkAsRead)
  markAsRead(ctx: StateContext<ClientMenuStateModel>, action: MarkAsRead) {
    return this.pushService.markAsRead(action.id).pipe(
      tap(() => {
        const state = ctx.getState();
        const updatedNotifications = state.notifications.map(notification =>
          notification.id === action.id ? { ...notification, read: true } : notification,
        );
        ctx.patchState({ notifications: updatedNotifications });
      }),
    );
  }
}
