import { Component, Input } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Device } from '@capacitor/device';

@Component({
  selector: 'app-payment-split',
  templateUrl: './payment-split.component.html',
  styleUrls: ['./payment-split.component.scss'],
  providers: [InAppBrowser],
})
export class PaymentSplitComponent {
  @Input() isSkipped = false;
  @Input() weekSplitPrice = 0;
  @Input() total = 0;
  @Input() discount = 0;

  constructor(private iab: InAppBrowser) {}

  async openExternalLink(url: string) {
    const { platform } = await Device.getInfo();

    if (platform === 'web') {
      window.open(url, '_blank');
      return;
    }

    this.iab.create(url, '_system', 'location=yes');
  }
}
