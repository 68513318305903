enum ActionTypes {
  ONE_SIGNAL_INIT = '[Notifications] One Signal init',
  UPDATE_DEVICE_INFO = '[Notifications] Update device push info',
  SETUP_BINDING_TOKEN = '[Notifications] Setup binding token',
  DELETE_TOKEN_BINDING = '[Notification] Delete token binding',
}

export class OneSignalInit {
  static readonly type = ActionTypes.ONE_SIGNAL_INIT;
}

export class SetupBindingToken {
  static readonly type = ActionTypes.SETUP_BINDING_TOKEN;
}

export class DeleteTokenBinding {
  static readonly type = ActionTypes.DELETE_TOKEN_BINDING;
}

export class UpdateDeviceInfo {
  static readonly type = ActionTypes.UPDATE_DEVICE_INFO;
}
