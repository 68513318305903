import { ClientMenuDish } from '../models';

/**
 * Groups dishes with the same titles and merges their properties.
 * @param filteredDishes - The list of dishes to be processed.
 * @returns A new array of dishes with merged properties for those with the same titles.
 */
export function groupDishesWithSameTitles(filteredDishes: ClientMenuDish[]): ClientMenuDish[] {
  // Group dishes by their title
  const groupedDishes: { [title: string]: ClientMenuDish[] } = {};
  filteredDishes.forEach(dish => {
    const { title } = dish;
    if (!groupedDishes[title]) {
      groupedDishes[title] = [];
    }
    groupedDishes[title].push(dish);
  });

  // Create an array of groups with more than one dish
  const groupedArray = Object.values(groupedDishes).filter(group => group.length > 1);

  // Merge properties of dishes within the same group
  const mergedGroupedArray = groupedArray.map(group => {
    const baseDish = group[0];
    const merged: any = {
      id: group.map(dish => dish.id),
      title: baseDish.title,
      mealType: baseDish.mealType,
      ingredients: baseDish.ingredients,
      containerType: baseDish.containerType,
      isSameMenuType: baseDish.isSameMenuType,
      allowForChildren: baseDish.allowForChildren,
      ingredientCategories: [...new Set(group.flatMap(dish => dish.ingredientCategories))],
      imageUrls: baseDish.imageUrls,
      hasDuplicateDish: true,
    };

    // Merge other properties
    Object.keys(baseDish).forEach(key => {
      if (
        key !== 'id' &&
        key !== 'title' &&
        key !== 'ingredients' &&
        key !== 'containerType' &&
        key !== 'isSameMenuType' &&
        key !== 'ingredientCategories' &&
        key !== 'imageUrls' &&
        key !== 'mealType' &&
        key !== 'allowForChildren'
      ) {
        merged[key] = mapGroupProperties(group, key as keyof ClientMenuDish);
      }
    });

    return merged as ClientMenuDish;
  });

  // Filter out original dishes that were grouped and merged
  const groupedIds = new Set(mergedGroupedArray.flatMap(group => group.id));
  const filtered = filteredDishes.filter(dish => !groupedIds.has(dish.id));

  return [...filtered, ...mergedGroupedArray];
}

/**
 * Maps properties of dishes in a group to a new format.
 * @param group - The group of dishes.
 * @param property - The property to be mapped.
 * @returns A new array of mapped properties.
 */
export function mapGroupProperties(group: ClientMenuDish[], property: keyof ClientMenuDish) {
  return group.map(dish => ({ [dish.id]: dish[property] }));
}
