/* eslint-disable max-classes-per-file */
import { ProgramTypesEnum } from '@shared/enums';
import { CurrentProgram, DayItem, ProgramDurationInfo, SubscriptionInfo } from '@shared/models';

enum ActionTypes {
  INIT_PROGRAM = '[Program] Init program',
  SET_PROGRAM = '[Program] Set program',
  SET_IS_FAMILY = '[Program] Set is family subscription',
  SET_IS_HIDE_FAMILY = '[Program] Set is hide family subscription',
  SET_PROMOCODE = '[Program] Set promocode',
  SET_PROMOCODE_ENABLEMENT_STATUS = '[Program] Set promocode enablement status',
  SET_PROMOCODE_ERROR_MESSAGE = '[Program] Set promocode error message',

  UPDATE_PROGRAM_PRICE = '[Program] Update program price',
  SET_PROGRAM_DISCOUNT = '[Program] Set program discount',

  SET_START_DELIVERY_DAY = '[Program] Set start delivery day',
  UPDATE_DELIVERY_INTERVAL_INFO = '[Program] Update delivery interval info',
  GET_CALENDAR_AVAILABLE_MOVING_DAYS = '[ClientSubscriptions] Get calendar available moving dates',

  LOAD_AVAILABLE_DAYS = '[Program] Load available days',
  REFRESH_PROGRAM_DATA = '[Program] Refresh program data',

  LOAD_SUBSCRIPTION_BY_ID = '[Program] Load subscription by Id',
  RENEW_SUBSCRIPTION_BY_ID = '[Program] Renew subscription by Id',
}

export class InitProgram {
  static readonly type = ActionTypes.INIT_PROGRAM;
  constructor(public data: Partial<CurrentProgram> = null) {}
}

export class SetProgram {
  static readonly type = ActionTypes.SET_PROGRAM;
  constructor(public data: Partial<CurrentProgram>) {}
}

export class SetPromocode {
  static readonly type = ActionTypes.SET_PROMOCODE;
  constructor(public promocode: string, public type: 'fromInput' | 'fromUrl') {}
}

export class SetPromocodeErrorMessage {
  static readonly type = ActionTypes.SET_PROMOCODE_ERROR_MESSAGE;
  constructor(public errorMessage: string = '') {}
}

export class SetPromocodeEnablementStatus {
  static readonly type = ActionTypes.SET_PROMOCODE_ENABLEMENT_STATUS;
  constructor(public isPromocodeEnabled: boolean) {}
}
export class UpdateProgramPrice {
  static readonly type = ActionTypes.UPDATE_PROGRAM_PRICE;
  constructor(public promocode?: string) {}
}

export class SetProgramDiscount {
  static readonly type = ActionTypes.SET_PROGRAM_DISCOUNT;
  constructor(public durationOptions: ProgramDurationInfo[]) {}
}

export class SetStartDeliveryDay {
  static readonly type = ActionTypes.SET_START_DELIVERY_DAY;
  constructor(public startDeliveryDay: string) {}
}

export class UpdateDeliveryIntervalInfo {
  static readonly type = ActionTypes.UPDATE_DELIVERY_INTERVAL_INFO;
  constructor() {}
}

export class LoadAvailableDays {
  static readonly type = ActionTypes.LOAD_AVAILABLE_DAYS;
  constructor(public isAuthorized: boolean) {}
}

export class GetCalendarAvailableMovingDays {
  static readonly type = ActionTypes.GET_CALENDAR_AVAILABLE_MOVING_DAYS;
  constructor(public dayItem: DayItem, public subscriptionId: string) {}
}

export class RefreshProgramData {
  static readonly type = ActionTypes.REFRESH_PROGRAM_DATA;
}

export class SetIsFamily {
  static readonly type = ActionTypes.SET_IS_FAMILY;
  constructor(public isFamily: boolean) {}
}

export class SetIsHideFamily {
  static readonly type = ActionTypes.SET_IS_HIDE_FAMILY;
  constructor(public isHideFamily: boolean) {}
}

export class LoadSubscriptionById {
  static readonly type = ActionTypes.LOAD_SUBSCRIPTION_BY_ID;
  constructor(public subscriptionId: string) {}
}

export class RenewSubscriptionById {
  static readonly type = ActionTypes.RENEW_SUBSCRIPTION_BY_ID;
  constructor(public subscriptionId: string) {}
}
