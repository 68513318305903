import { ChangeDetectionStrategy, Component, Inject, OnInit, Self } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { DOCUMENT } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';
import { Observable, debounceTime, filter, map, takeUntil, tap } from 'rxjs';
import { Select, Store } from '@ngxs/store';

import { AuthSelectors, SendInputPhone, VerifyCode } from '@store/auth';
import { TimerService } from '@modules/login/services/timer.service';
import { NgOnDestroyService } from '@shared/services';
import { Platform } from '@ionic/angular';

declare let SMSRetriever: any;
@Component({
  templateUrl: './login-code-input.component.html',
  styleUrls: ['./login-code-input.component.scss'],
  providers: [NgOnDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginCodeInputComponent implements OnInit {
  @Select(AuthSelectors.isSendingCode) public isSendingCode$: Observable<boolean>;
  @Select(AuthSelectors.codeError) public codeError$: Observable<string>;
  @Select(AuthSelectors.authErrorMessage) public authErrorMessage$: Observable<string>;

  public verificationCodeControl = new FormControl('', {
    validators: [Validators.required, Validators.minLength(4)],
  });

  get codeErrorMessage(): string {
    return this.verificationCodeControl.errors?.authCodeError || 'Введите код';
  }

  public isTimeout$ = this.timerService?.timeLeft$.pipe(map(timeLeft => timeLeft > 0));

  public secondsLeft$ = this.timerService?.timeLeftForView$;

  constructor(
    @Inject(DOCUMENT) private dom: Document,
    @Self() private ngOnDestroy$: NgOnDestroyService,
    private store: Store,
    private timerService: TimerService,
    private platform: Platform,
    private androidPermissions: AndroidPermissions,
  ) {}

  ngOnInit(): void {
    this.focusInput('native-input');
    this.listenAuthErrorMessage();
    this.listenVerificationCodeSubscription();

    this.platform.ready().then(() => {
      if (this.platform.is('android')) {
        this.startSmsRetriever();
        this.requestPermissions();
      }
    });
  }

  public startSmsRetriever() {
    if (!(window as any)?.SMSRetriever) {
      return;
    }
    SMSRetriever.startWatching(
      (msg: string) => {
        if (msg.includes('justfood')) {
          const code = this.extractVerificationCode(msg);
          if (code) {
            this.verificationCodeControl.setValue(code);
          }
        }
      },
      (error: any) => {
        console.error('Error starting SMS Retriever:', error);
      },
    );
  }

  extractVerificationCode(message: string): string | null {
    const codeRegex = /\b(\d{4})\b/;
    const matches = message.match(codeRegex);
    return matches ? matches[0] : null;
  }

  requestPermissions() {
    this.androidPermissions
      .requestPermissions([this.androidPermissions.PERMISSION.RECEIVE_SMS, this.androidPermissions.PERMISSION.READ_SMS])
      .then(
        result => console.log('Permissions granted:', result),
        err => console.error('Permissions denied:', err),
      );
  }

  public checkVerificationCode(): void {
    if (this.verificationCodeControl.invalid) {
      this.verificationCodeControl.markAsTouched();
      return;
    }

    const code = this.verificationCodeControl.value;

    this.store.dispatch(new VerifyCode(code));
  }

  public resendVerificationCode(): void {
    this.store.dispatch(new SendInputPhone());
  }

  private listenVerificationCodeSubscription(): void {
    this.verificationCodeControl.valueChanges
      .pipe(
        debounceTime(300),
        filter(() => this.verificationCodeControl.valid),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe(() => {
        this.checkVerificationCode();
      });
  }

  private listenAuthErrorMessage(): void {
    this.authErrorMessage$
      .pipe(
        filter<string>(Boolean),
        tap(() => {
          this.verificationCodeControl.setErrors({ authCodeError: true });
          this.verificationCodeControl.markAllAsTouched();
        }),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe();
  }

  private focusInput(className: string): void {
    setTimeout(() => {
      const input: HTMLInputElement = this.dom.querySelector(`.${className}`);

      if (input) {
        input.focus();
      }
    }, 1000);
  }
}
