import { ProgramDurationsEnum } from '@shared/enums';
import {
  AvailableDiscount,
  AvailableMovingDays,
  CurrentProgram,
  DeliveryIntervalResp,
  SubscriptionInfoForThanksPage,
  SubscriptionPriceResponse,
} from '@shared/models';

export interface ProgramStateModel {
  program: CurrentProgram;
  isFamily: boolean;
  isHideFamily: boolean;
  promocodeFromInput: string;
  promocodeFromUrl: string;
  isPromocodeEnabled: boolean;
  startDeliveryDay: string;
  availableDates: string[];
  calendarAvailableMovingDays: AvailableMovingDays;
  isLoadingMovingDays: boolean;
  promocodeErrorMessage: string;
  deliveryIntervalInfo?: DeliveryIntervalResp;
  priceData: SubscriptionPriceResponse;
  availableDiscounts: AvailableDiscount[];
  subscriptionInfo: SubscriptionInfoForThanksPage;
}
