import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, interval, mergeMap, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Action, State, StateContext, NgxsOnInit, Store } from '@ngxs/store';
import { Preferences } from '@capacitor/preferences';

import { LocalStorageKeysEnum, PaymentPurposeEnum, PaymentRespStatusesEnum, PaymentSbpStatusEnum, PaymentStatusEnum } from '@shared/enums';
import {
  SetPaymentSystemErrorResp,
  SetPaymentSystemResp,
  SetStartedPayment,
  ClearStartedPayment,
  SetCreatedRenewedSubscription,
  SetSubscriptionCreationResp,
  ClearPaymentDetails,
  ListenPaymentSbpStatus,
  LoadCards,
  LoadPaymentDetails,
  PayByNewCard,
  PayBySavedCard,
  PayBySbp,
  SetActiveCard,
  SetThanksPageText,
  StopListenPaymentSbpStatus,
} from './payment.actions';
import { PaymentStateModel } from './payment.model';
import { PaymentApiService, SentryService, RudderStackService } from '@shared/services';
import { ClientMenuEventsEnum } from '@modules/client-menu/enums';
import { Card, PaymentDetails, PayCryptogramResponse } from '@shared/models';
import { DeleteSubscription, LoadSubscriptions, ClearChangesInActiveSubscription } from '@store/client-menu';
import { ProgramSelectors, SetIsFamily, SetIsHideFamily, SetPromocode } from '@store/program';
import { environment } from 'src/environments/environment';

@State<PaymentStateModel>({
  name: 'payment',
  defaults: {
    startedPaymentId: null,
    subscriptionCreationResp: null,
    paymentDetails: null,
    paymentSystemResp: null,
    paymentSystemErrorResp: null,
    createdRenewedSubscription: null,
    cards: [],
    activeCardId: null,
    sbpInfoResponse: null,
    paymentSbpStatus: null,
    thanksTitle: 'Оплата прошла успешно',
    thanksText: null,
    thanksBtnText: null,
  },
})
@Injectable()
export class PaymentState implements NgxsOnInit {
  private isCompleted$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private store: Store,
    private paymentApiService: PaymentApiService,
    private sentryService: SentryService,
    private rudderstack: RudderStackService,
  ) {}

  ngxsOnInit() {
    this.initStartedPayment();
  }

  private async initStartedPayment(): Promise<void> {
    const data = await Preferences.get({
      key: LocalStorageKeysEnum.startedPaymentId,
    });
    const paymentId = data?.value;

    if (paymentId && paymentId !== 'undefined') {
      this.store.dispatch(new SetStartedPayment(paymentId, false));
    }
  }

  @Action(SetSubscriptionCreationResp)
  setSubscriptionCreationResp(ctx: StateContext<PaymentStateModel>, { subscriptionCreationResp }: SetSubscriptionCreationResp): void {
    ctx.patchState({ subscriptionCreationResp });

    Preferences.set({
      key: LocalStorageKeysEnum.createdSubscriptionGuid,
      value: subscriptionCreationResp?.createdSubscriptionGuid,
    });
  }

  @Action(SetPaymentSystemResp)
  setPaymentSystemResp(ctx: StateContext<PaymentStateModel>, { paymentSystemResp }: SetPaymentSystemResp): void {
    ctx.patchState({
      paymentSystemResp,
    });
  }

  @Action(SetPaymentSystemErrorResp)
  setPaymentSystemErrorResp(ctx: StateContext<PaymentStateModel>, { paymentSystemErrorResp }: SetPaymentSystemErrorResp): void {
    ctx.patchState({
      paymentSystemErrorResp,
    });
  }

  @Action(SetStartedPayment)
  setStartedPayment({ patchState }: StateContext<PaymentStateModel>, { paymentId, shouldSaveToLocalStorage }: SetStartedPayment) {
    if (!paymentId) {
      return;
    }

    patchState({
      startedPaymentId: paymentId,
    });

    if (shouldSaveToLocalStorage) {
      Preferences.set({
        key: LocalStorageKeysEnum.startedPaymentId,
        value: paymentId,
      });
    }
  }

  @Action(ClearStartedPayment)
  clearStartedPayment({ patchState }: StateContext<PaymentStateModel>) {
    Preferences.remove({ key: LocalStorageKeysEnum.startedPaymentId });

    patchState({
      startedPaymentId: null,
    });
  }

  @Action(SetCreatedRenewedSubscription)
  setCreatedRenewedSubscription(
    { patchState }: StateContext<PaymentStateModel>,
    { createdRenewedSubscription }: SetCreatedRenewedSubscription,
  ): void {
    patchState({
      createdRenewedSubscription,
    });
  }

  @Action(LoadCards)
  loadCards({ patchState }: StateContext<PaymentStateModel>, { paymentId }: LoadCards): Observable<Card[]> {
    if (!paymentId) {
      return;
    }

    return this.paymentApiService.getCardsByPaymentId(paymentId).pipe(
      tap(cards => {
        patchState({ cards, activeCardId: cards[0]?.guid || null });
      }),
    );
  }

  @Action(LoadPaymentDetails)
  loadPaymentDetails({ patchState }: StateContext<PaymentStateModel>, { paymentId }: LoadPaymentDetails): Observable<PaymentDetails> {
    if (!paymentId) {
      return;
    }

    return this.paymentApiService.getPaymentDetails(paymentId).pipe(
      tap(paymentDetails => {
        // Если платеж уже невалиден, то удаляем все данные по этому платежу!
        if (paymentDetails.status === PaymentStatusEnum.Canceled) {
          patchState({ paymentDetails: null });

          Preferences.remove({ key: LocalStorageKeysEnum.startedPaymentId });

          return;
        }

        patchState({ paymentDetails });
      }),
    );
  }

  @Action(ClearPaymentDetails)
  clearPaymentDetails({ patchState }: StateContext<PaymentStateModel>, { paymentId }: LoadPaymentDetails): void {
    patchState({ paymentDetails: null });
  }

  @Action(SetActiveCard)
  setActiveCard({ patchState }: StateContext<PaymentStateModel>, { activeCardId }: SetActiveCard): void {
    patchState({ activeCardId });
  }

  @Action(PayByNewCard)
  payByNewCard({ dispatch, getState, patchState }: StateContext<PaymentStateModel>, { clientId }: PayByNewCard): void {
    patchState({ paymentSystemResp: null });

    const { paymentDetails, startedPaymentId } = getState();

    if (!paymentDetails) {
      console.warn('PayByNewCard: отсутствует paymentDetails!');

      return;
    }

    if (!startedPaymentId) {
      console.warn('PayByNewCard: отсутствует startedPaymentId!');

      return;
    }

    // Отправляем кастомное событие в Sentry
    this.sentryService.sendEvent('Переход на виджет оплаты подписки', {
      publicId: environment.cloudPaymentsPublicId,
      description: 'Оплата заказа justfood из мобильного приложения',
      amount: paymentDetails.amount.toString(),
      currency: 'RUB',
      invoiceId: startedPaymentId,
    });

    // Чтобы не возвращаться на страницу оплаты после успешной оплаты =)
    // https://justfood.atlassian.net/browse/JFPRD-3731
    this.router.navigate(['cabinet', 'create', 'list']);

    const widget = new (window as any).cp.CloudPayments();

    widget
      .pay('charge', {
        publicId: environment.cloudPaymentsPublicId,
        description: 'Оплата заказа justfood из мобильного приложения',
        amount: paymentDetails.amount,
        currency: 'RUB',
        invoiceId: startedPaymentId,
        accountId: clientId,
        skin: 'classic',
      })
      .then(async ({ status }) => {
        const data = await Preferences.get({ key: LocalStorageKeysEnum.createdSubscriptionGuid });
        const createdSubscriptionGuid = data?.value;

        // Сценарий при неуспешной оплате
        if (status !== 'success') {
          if (paymentDetails.purpose === PaymentPurposeEnum.Subscription) {
            dispatch(new DeleteSubscription(createdSubscriptionGuid));
          }

          // Переходим на страницу 'Спасибо'
          patchState({ thanksTitle: 'К сожалению, оплата не удалась' });
          const btnText = 'Попробовать снова';
          this.router.navigate(['cabinet', 'thanks'], { state: { btnText } });

          return;
        }

        if (paymentDetails.purpose === PaymentPurposeEnum.Subscription) {
          dispatch([new LoadSubscriptions(), new SetPromocode('', 'fromInput')]);

          // Если активен тоггл семейной подписки, то переходим на страницу оформления
          const isFamily = this.store.selectSnapshot(ProgramSelectors.isFamily);
          if (isFamily) {
            this.store.dispatch([new SetIsHideFamily(true), new SetIsFamily(false)]);

            const state = { subscriptionId: createdSubscriptionGuid };
            this.router.navigate(['cabinet', 'create', 'program'], { state });

            return;
          }
        }

        if (paymentDetails.purpose !== PaymentPurposeEnum.Subscription) {
          // Очищаем все изменения в подписке после успешной замены
          dispatch(new ClearChangesInActiveSubscription());
        }

        // Отправляем аналитику
        this.rudderstack.track(ClientMenuEventsEnum.successfulPayment);

        // Переходим на страницу 'Спасибо'
        patchState({
          thanksTitle: paymentDetails.purpose === PaymentPurposeEnum.Subscription ? 'Подписка оформлена!' : 'Вы подтвердили замену блюд',
        });
        this.router.navigate(['cabinet', 'create', 'list']).then(() => this.router.navigate(['cabinet', 'thanks']));
      });
  }

  @Action(PayBySavedCard)
  payBySavedCard({ patchState, getState }: StateContext<PaymentStateModel>, { paymentGuid, cardId }: PayBySavedCard): Observable<any> {
    patchState({ paymentSystemResp: null, createdRenewedSubscription: null });

    const { paymentDetails, createdRenewedSubscription } = getState();

    if (!paymentDetails) {
      console.warn('@Action(PayByNewCard): отсутствует paymentDetails!');

      return;
    }

    return this.paymentApiService.payBySavedCard(paymentGuid, cardId).pipe(
      tap((resp: PayCryptogramResponse) => {
        if (!resp || !resp.status) {
          return;
        }

        if (resp.status === PaymentRespStatusesEnum.success) {
          new SetPromocode('', 'fromInput');

          // Если активен тоггл семейной подписки, то переходим на страницу оформления
          const isFamily = this.store.selectSnapshot(ProgramSelectors.isFamily);
          if (isFamily) {
            this.store.dispatch([new SetIsHideFamily(true), new SetIsFamily(false)]);

            const state = { subscriptionId: createdRenewedSubscription.createdSubscriptionGuid };
            this.router.navigate(['cabinet', 'create', 'program'], { state });

            return;
          }

          patchState({
            paymentSystemResp: null,
            createdRenewedSubscription: null,
            thanksTitle: paymentDetails.purpose === PaymentPurposeEnum.Subscription ? 'Подписка оформлена!' : 'Оплата прошла успешно',
            thanksText:
              paymentDetails.purpose === PaymentPurposeEnum.Subscription ? '' : 'Наш менеджер свяжется с вами для подтверждения заказа',
          });
          this.router.navigate(['cabinet', 'create', 'list']).then(() => this.router.navigate(['cabinet', 'thanks']));
        }

        if (resp.status === PaymentRespStatusesEnum.rejected) {
          patchState({ paymentSystemResp: resp });
        }
      }),
      catchError(paymentSystemErrorResp => {
        patchState({ paymentSystemErrorResp });

        return EMPTY;
      }),
    );
  }

  @Action(PayBySbp)
  payBySbp({ patchState, getState, dispatch }: StateContext<PaymentStateModel>, { paymentId }: PayBySbp): Observable<any> {
    return this.paymentApiService.payBySbp(paymentId).pipe(
      tap(sbpInfoResponse => {
        this.isCompleted$.next(true);
        patchState({ sbpInfoResponse });

        // Нужно обновить ID платежа, если он изменился!
        if (sbpInfoResponse?.newPaymentId) {
          const { subscriptionCreationResp } = getState();

          dispatch(new SetStartedPayment(sbpInfoResponse.newPaymentId));

          patchState({
            subscriptionCreationResp: {
              ...subscriptionCreationResp,
              paymentGuid: sbpInfoResponse?.newPaymentId,
            },
          });
        }

        dispatch(new ListenPaymentSbpStatus(sbpInfoResponse?.newPaymentId || paymentId));
      }),
    );
  }

  @Action(ListenPaymentSbpStatus)
  listenPaymentSbpStatus({ getState, patchState }: StateContext<PaymentStateModel>, { paymentId }: ListenPaymentSbpStatus): void {
    this.isCompleted$.next(false);

    const { paymentDetails, createdRenewedSubscription } = getState();

    const status$ = interval(2000).pipe(
      mergeMap(() => this.paymentApiService.getPaymentSbpStatus(paymentId)),
      tap(paymentSbpStatus => {
        patchState({ paymentSbpStatus });
        if (paymentSbpStatus === PaymentSbpStatusEnum.Completed) {
          patchState({
            paymentSbpStatus: null,
            paymentSystemResp: null,
            thanksTitle: paymentDetails.purpose === PaymentPurposeEnum.Subscription ? 'Подписка оформлена!' : 'Оплата прошла успешно',
          });

          if (paymentDetails.purpose === PaymentPurposeEnum.Subscription) {
            // Если активен тоггл семейной подписки, то переходим на страницу оформления
            const isFamily = this.store.selectSnapshot(ProgramSelectors.isFamily);
            if (isFamily) {
              this.store.dispatch([new SetIsHideFamily(true), new SetIsFamily(false)]);

              const state = { subscriptionId: createdRenewedSubscription?.createdSubscriptionGuid };
              this.router.navigate(['cabinet', 'create', 'program'], { state });

              return;
            }
          }

          this.router.navigate(['cabinet', 'thanks']);
          this.isCompleted$.next(true);
        }
      }),
      catchError(() => EMPTY),
      takeUntil(this.isCompleted$),
    );

    status$.subscribe();

    /**
     * На случай если, пользователь закрывает окно браузера,
     * а затем снова открывает. На iPhone иначе не работает.
     * Задача: https://justfood.atlassian.net/browse/JFPRD-2768
     */
    document.onvisibilitychange = () => {
      if (document.visibilityState === 'hidden') {
        this.isCompleted$.next(true);

        return;
      }

      this.isCompleted$.next(false);
      status$.subscribe();
    };
  }

  @Action(StopListenPaymentSbpStatus)
  stopListenPaymentSbpStatus({ patchState }: StateContext<PaymentStateModel>): void {
    patchState({ paymentSbpStatus: null });
    this.isCompleted$.next(true);
  }

  @Action(SetThanksPageText)
  setThanksPageText({ patchState }: StateContext<PaymentStateModel>, { title, text, btnText }: SetThanksPageText): void {
    if (title) {
      patchState({ thanksTitle: title });
    }

    if (text) {
      patchState({ thanksText: text });
    }

    if (btnText) {
      patchState({ thanksBtnText: btnText });
    }
  }
}
