import { ClientMenuDish } from '@modules/client-menu/models';
import { ClientSubscription, ClientSubscriptionDetails, ClientSubscriptionPackage, DayItem, Notification } from '@shared/models';

export interface ReplacementDiff {
  packageId: string;
  originalUuid: string;
  newUuid: string;
}

export interface ReplacementDiffDishes {
  packageId: string;
  originalDish: ClientMenuDish;
  newDish: ClientMenuDish;
}

export interface ClientMenuStateModel {
  activeSubscriptionDetails: ClientSubscriptionDetails;
  subscriptionList: ClientSubscription[];
  areSubscriptionsLoaded: boolean;
  activePackage: ClientSubscriptionPackage;
  dishesMap: ClientMenuDishesMap;
  changedDish: { [subscriptionId: string]: ReplacementDiffDishes[] };
  replacementDishes: ClientMenuDish[];
  isSubscriptionListLoading: boolean;
  isMenuLoading: boolean;
  isCalendarLoading: boolean;
  isSubscriptionDetailsLoading: boolean;
  didPageFail: boolean;
  ingredientsList: Ingredient[];
  isIngredientsExclusionEnabled: boolean;
  isDialogIngredientsExclusionEnabled: boolean;
  notifications: Notification[];
}

export interface Ingredient {
  name: string;
  isSelected: boolean;
}

export interface ClientMenuDishesMap {
  [dishUuid: string]: ClientMenuDish;
}
