<app-modal-layout [showBack]="true" [title]="title" (onGoBack)="closeModal()" (onClose)="closeModal()">
  <div [ngClass]="{ wrapper: true, 'filtering-dishes': areIngredientFiltering$ | async }">
    <div class="dialog-header">
      <div class="modal-sorting">
        <app-icon icon="sort" class="sort-icon" (click)="showSortDropdown()"></app-icon>
        <div class="dropdown header-dropdown-menu" [class.shown]="isSortingDropdownShown$ | async">
          <p class="dropdown-title">Как сортировать блюда?</p>
          <div class="dropdown-options">
            <app-radio-toggler
              class="save-card-toggle"
              [value]="filterMethod === 'filterByCcal'"
              (changeValue)="selectFilterMethod('filterByCcal')"
              >По разнице ккал</app-radio-toggler
            >
            <app-radio-toggler
              class="save-card-toggle"
              [value]="filterMethod === 'filterByTitle'"
              (changeValue)="selectFilterMethod('filterByTitle')"
              >По алфавиту</app-radio-toggler
            >
            <app-radio-toggler
              class="save-card-toggle"
              [value]="filterMethod === 'filterByPrice'"
              (changeValue)="selectFilterMethod('filterByPrice')"
              >По цене</app-radio-toggler
            >
          </div>

          <button class="btn sort-btn" (click)="sortDishes()">Показать</button>
        </div>
      </div>
    </div>

    <div class="loading filtering" *ngIf="areIngredientFiltering$ | async">
      <div class="loading-content">
        <div class="dot-flashing"></div>
      </div>
    </div>

    <div class="loading" *ngIf="isLoading$ | async; else contentBlock">
      <div class="loading-content">
        <p class="msg text--18">Загружаем блюда</p>
        <div class="dot-flashing"></div>
      </div>
    </div>

    <ng-template #contentBlock>
      <ng-container *ngIf="dishesList?.length; else noResultsBlock">
        <div class="meal-type-tabs">
          <div
            class="item"
            [ngClass]="{ active: activeMealTypeTab === category.key }"
            *ngFor="let category of dishesCategories | keyvalue: $any(sortByMealTypeFn); trackBy: trackByFn"
            (click)="scrollToCategory(category.key)"
          >
            {{ RuDishTypesEnum[category.key] }}
          </div>
        </div>

        <div class="dishes-list" #dishesListRef>
          <div
            class="row dish-row"
            *ngFor="let category of dishesCategories | keyvalue: $any(sortByMealTypeFn); trackBy: trackByFn"
            [ngClass]="category.key"
            [id]="category.key"
            appVisibilityObserver
            (visibilityChange)="setActiveCategory($event, category.key)"
          >
            <ng-container *ngTemplateOutlet="categoryTemplate; context: { title: category.key, dishes: category.value }"> </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-template #noResultsBlock>
        <div class="empty-list">
          <p class="msg text--18">Нет доступных блюд</p>
        </div>
      </ng-template>
    </ng-template>
  </div>

  <ng-template #categoryTemplate let-title="title" let-dishes="dishes">
    <div class="meal-type-col">
      <p class="text--16 meal-type-title">{{ RuDishTypesEnum[title] }}</p>
    </div>

    <div class="list">
      <ng-container *ngFor="let dish of dishes">
        <app-dish-item-replaced
          [dish]="dish"
          [package]="package"
          (selectDish)="select(dish)"
          [isFromAdditionModal]="true"
        ></app-dish-item-replaced>
      </ng-container>
    </div>
  </ng-template>

  <button *ngIf="isShowSkipButton" class="btn skip-btn" (click)="closeModal()">Пропустить</button>
</app-modal-layout>
