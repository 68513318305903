import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, EMPTY, from, of, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { DeviceInfo, Device } from '@capacitor/device';

import { AuthService } from '@shared/services';
import { CookieHeadersEnum, LocalStorageKeysEnum } from '@shared/enums';
import { environment } from 'src/environments/environment';
import packageData from 'package.json';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private toastController: ToastController) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('crm.justfood.pro') && !req.url.includes('localhost')) {
      return next.handle(req);
    }

    if (req.headers.has(CookieHeadersEnum.shouldSkipInterceptor)) {
      const headers = req.headers.delete(CookieHeadersEnum.shouldSkipInterceptor);
      return next.handle(req.clone({ headers })).pipe(
        catchError(({ status }: HttpErrorResponse) => {
          if (status === 401 || status >= 500) {
            this.authService.logout();
          }

          return EMPTY;
        }),
      );
    }

    return from(this.getRequestWithOptions(req)).pipe(
      switchMap(refreshedRequest => next.handle(refreshedRequest)),
      catchError((error: HttpErrorResponse) => this.handleRequestError(error, next, req)),
    );
  }

  private handleRequestError(error: HttpErrorResponse, next: HttpHandler, request: HttpRequest<any>): Observable<any> {
    if (!environment.production) {
      this.presentToast('bottom', error);
    }

    if (error.status === 400 && error.url.includes('mobile/subscription')) {
      this.presentToast('bottom', error);
    }

    if ((error.status === 400 || error.status === 422) && error.url.includes('mobile/v2/subscription-price')) {
      this.presentToast('bottom', error);
      return throwError(() => error);
    }

    if (error.status !== 401) {
      return throwError(() => error);
    }

    return from(this.authService.refreshToken()).pipe(
      switchMap(() => from(this.getRequestWithOptions(request))),
      mergeMap(refreshedRequest => {
        return next.handle(refreshedRequest);
      }),
    );
  }

  private async getRequestWithOptions(req: HttpRequest<any>): Promise<HttpRequest<any>> {
    const token = await this.authService.getToken();
    const visitorId = await Preferences.get({ key: LocalStorageKeysEnum.visitorId });
    const deviceInfo: DeviceInfo = await Device?.getInfo();
    const platform = deviceInfo?.platform;
    const isAndroid = platform === 'android';
    const appKey = !isAndroid ? environment.appKeyIOS : environment.appKeyAndroid;
    const utm_source = !isAndroid ? 'Mobile|IOS' : 'Mobile|Android';

    return req.clone({
      withCredentials: true,
      setHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
        appKey,
        authorization: `Bearer ${token}`,
        ClientVersion: `PE MobileApp ${packageData.version}`,
        ManualReferrer: `http://localhost?utm_source=${utm_source}`,
        'X-VisitorId': visitorId.value,
      },
    });
  }

  private async presentToast(position: 'top' | 'middle' | 'bottom', error: HttpErrorResponse) {
    const toast = await this.toastController.create({
      header: 'Ошибка ' + error.status,
      message: error?.error?.error || 'Неизвестная ошибка',
      duration: 3000,
      position: position,
      color: error.status >= 500 ? 'danger' : 'warning',
      icon: 'alert-circle',
      mode: 'ios',
    });

    await toast.present();
  }
}
