import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgOnDestroyService } from '@shared/services';
import { ClientMenuSelectors } from '@store/client-menu';
import { NotificationsSelectors } from '@store/notifications';

@Component({
  selector: 'app-layout-container',
  templateUrl: './layout-container.component.html',
  styleUrls: ['./layout-container.component.scss'],
  providers: [NgOnDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutContainerComponent {
  public subscriptionList$ = inject(Store).select(ClientMenuSelectors.subscriptionsList);
  public deviceInfo$ = inject(Store).select(NotificationsSelectors.deviceInfo);
}
