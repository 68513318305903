<div class="container content phone">
  <form class="form form-phone" [ngClass]="{ 'locked': isGettingCode$ | async }" (submit)="sendPhone()">
    <div class="form-label">Номер телефона</div>

    <app-text-input
      class="form-input"
      [formControl]="phoneNumberControl"
      customInputClass="auth-phone-input"
      inputType="tel"
      autocomplete="tel"
      inputId="phone"
      [errorMessages]="{
        'required': phoneErrorMessage,
        'mask': phoneErrorMessage,
        'serverError': (codeError$ | async)
      }"
      [prefix]="phoneInputPrefix"
      mask="(000) 000-00-00"
    ></app-text-input>

    <button
      [disabled]="(isGettingCode$| async) || (isTimeout$ | async) || phoneNumberControl.invalid"
      class="btn-base send-code-btn"
      type="submit"
    >
      Далее
    </button>

    <ng-container *ngIf="codeError$ | async as codeError">
      <p class="error-msg">{{ codeError }}</p>
    </ng-container>

    <div class="agreement">
      Отправляя код, вы принимаете
      <span class="agreement-link" (click)="openExternalLink('https://justfood.pro/assets/documents/pe/agreement_sms.pdf')"
        >условия обработки персональных данных</span
      >, соглашаетесь с
      <span class="agreement-link" (click)="openExternalLink('https://justfood.pro/assets/documents/pe/privacy_policy.pdf')"
        >Политикой обработки персональных данных</span
      >, даёте
      <span class="agreement-link" (click)="openExternalLink('https://justfood.pro/assets/documents/pe/agreement_2.pdf')"
        >согласие на получение электронных сообщений</span
      >
      и принимаете условия
      <span (click)="openExternalLink('https://justfood.pro/assets/documents/pe/oferta.pdf')" class="agreement-link">публичной оферты</span>
    </div>
  </form>

  <!-- <div *ngIf="isShowAgreements$ | async">
    /* Дима скажет, когда вырнуть этот кусок! */

    <div class="notifications-agreement">
      <ion-checkbox [ngModel]="privacyAgreement$ | async" (ionChange)="privacyAgreementClick($event)"></ion-checkbox>
      <ion-label>
        Нажимая «Получить код», вы принимаете <span>
          <a href="https://www.justfood.pro/policies_mobile" target="_blank">условия работы</a>
        </span> сервиса.
      </ion-label>
    </div>
    <div class="notifications-agreement">
      <ion-checkbox [ngModel]="notificationsAgreement$ | async" (ionChange)="notificationsAgreementClick($event)">
      </ion-checkbox>
      <ion-label> Я <a href="https://www.justfood.pro/assets/documents/agreement_2.pdf" target="_blank">согласен</a> получать уведомления о важных новостях, акциях и персональных
        предложениях.
      </ion-label>
    </div>
    <p class="skip" (click)="goToSubList()">Пропустить</p>
  </div> -->
  <p class="skip" (click)="goToSubList()">Пропустить</p>
</div>
