import { Injectable } from '@angular/core';

import { AppmetricaService } from './appmetrica.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private appmetricaService: AppmetricaService) {}

  init(): void {
    this.appmetricaService.init();
  }

  login(): void {
    this.appmetricaService.login();
  }

  purchase(isNewClient?: boolean): void {
    this.appmetricaService.purchase(isNewClient);
  }

  profileFilled(isNewClient?: boolean): void {
    this.appmetricaService.profileFilled(isNewClient);
  }

  orderCreated(isNewClient?: boolean): void {
    this.appmetricaService.orderCreated(isNewClient);
  }
}
