<div class="wrapper">
  <div *ngIf="!isSkipped" class="tabs">
    <div class="tab">1 неделя</div>
    <div class="tab">2 недели</div>
    <div class="tab active">4 недели<br /><span *ngIf="discount">-{{ discount }}%</span></div>
  </div>

  <div *ngIf="!isSkipped" class="payment-info">
    <div>
      <p class="text-16 text-bold">4 платежа по {{ weekSplitPrice | customNumber: 'price' }} ₽</p>
      <p class="text-14 total">Общая сумма: {{ total | customNumber: 'price' }} ₽</p>
    </div>

    <div>
      <span (click)="openExternalLink('https://justfood.pro/assets/documents/pe/split-sale.pdf')" class="text-12 text-thin link"
        >Условия акции</span
      >
    </div>
  </div>

  <div class="payment-schedule">
    <div class="payment-item active">
      <div class="date text-12 text-thin">Сегодня</div>
      <div class="price text-14 text-semibold">{{ weekSplitPrice | customNumber: 'price' }} ₽</div>
      <div class="line"></div>
    </div>

    <div class="payment-item">
      <div class="date text-12 text-thin">Через<br />1 неделю</div>
      <div class="price text-14 text-semibold">{{ weekSplitPrice | customNumber: 'price' }} ₽</div>
      <div class="line"></div>
    </div>

    <div class="payment-item">
      <div class="date text-12 text-thin">Через<br />2 недели</div>
      <div class="price text-14 text-semibold">{{ weekSplitPrice | customNumber: 'price' }} ₽</div>
      <div class="line"></div>
    </div>

    <div class="payment-item">
      <div class="date text-12 text-thin">Через<br />3 недели</div>
      <div class="price text-14 text-semibold">{{ weekSplitPrice | customNumber: 'price' }} ₽</div>
      <div class="line"></div>
    </div>
  </div>
</div>
