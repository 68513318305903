import { Injectable } from '@angular/core';
import { Appmetrica } from 'capacitor-appmetrica';

import { environment } from 'src/environments/environment';
import { AnalyticsEventEnum } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class AppmetricaService {
  constructor(private appmetrica: Appmetrica) {}

  init(): void {
    if (!environment.production) {
      return;
    }

    this.appmetrica.activate(environment.appMetricaId, { logs: true });
  }

  login(): void {
    if (!environment.production) {
      return;
    }

    this.appmetrica.reportEvent(AnalyticsEventEnum.login, {});
  }

  purchase(isNewClient: boolean): void {
    if (!environment.production) {
      return;
    }

    const parameters = typeof isNewClient === 'undefined' ? {} : { isNewClient };

    this.appmetrica.reportEvent(AnalyticsEventEnum.purchase, parameters);
  }

  orderCreated(isNewClient: boolean): void {
    if (!environment.production) {
      return;
    }

    const parameters = typeof isNewClient === 'undefined' ? {} : { isNewClient };

    this.appmetrica.reportEvent(AnalyticsEventEnum.orderCreated, parameters);
  }

  profileFilled(isNewClient: boolean): void {
    if (!environment.production) {
      return;
    }

    const parameters = typeof isNewClient === 'undefined' ? {} : { isNewClient };

    this.appmetrica.reportEvent(AnalyticsEventEnum.profileFilled, parameters);
  }
}
