<div
  [appTooltip]="tooltipText()"
  [isTooltipDisabled]="dish?.shouldBeConfirmed || package?.hasConfirmedCustomization || !package?.isCustomizable || dish?.isReplaceable"
  [ngClass]="{'full-width': isFromDetailsModal}"
  class="tooltip-wrapper"
  delay="100"
>
  <button
    *ngIf="!dish?.hasDuplicateDish && !dish?.shouldBeConfirmed && !(dish?.isReplaced && !package.hasConfirmedCustomization) && package.packageState !== PackageStateEnum.delivered && !isFromDetailsModal"
    class="can-replace"
    [disabled]="!package?.isCustomizable || package.hasConfirmedCustomization || !dish?.isReplaceable || package.packageState === PackageStateEnum.onCooking"
    (click)="openModal(dish)"
  >
    <app-icon icon="clockwise"></app-icon>
    <span>Заменить</span>
  </button>
</div>

<button
  *ngIf="!dish?.hasDuplicateDish && !dish?.shouldBeConfirmed && !(dish?.isReplaced && !package.hasConfirmedCustomization) && !isFromAdditionModal && isFromDetailsModal"
  [disabled]="package.hasConfirmedCustomization"
  class="text-btn black-button"
  [disabled]="!package?.isCustomizable || package.hasConfirmedCustomization || !dish?.isReplaceable || package.packageState === PackageStateEnum.onCooking"
  (click)="selectDishFromDetailsModal(dish)"
>
  Заменить блюдо
</button>

<button
  *ngIf="!dish?.hasDuplicateDish && !dish?.shouldBeConfirmed && !(dish?.isReplaced && !package.hasConfirmedCustomization) && isFromAdditionModal && isFromDetailsModal"
  [disabled]="package.hasConfirmedCustomization"
  class="text-btn black-button"
  [disabled]="!package?.isCustomizable"
  (click)="addDish(dish)"
>
  Добавить блюдо
</button>

<button *ngIf="isFromDetailsModal && dish?.hasDuplicateDish" class="text-btn black-button" (click)="openSelectPortionModal(dish)">
  Выбрать порцию
</button>
<button
  *ngIf="package?.isCustomizable && dish?.shouldBeConfirmed && !dish?.isAdded && !(dish?.isReplaced && !package.hasConfirmedCustomization)"
  class="text-btn"
  [ngClass]="{'black-button': isFromDetailsModal}"
  (click)="undoDishChange(dish.id)"
>
  Отменить замену
</button>
<button
  *ngIf="package?.packageState === 'Delivered' && !isFromDetailsModal && (dish.dishFeedback?.rating === 0 || dish.dishFeedback === null)"
  class="text-btn"
  (click)="setDishRating()"
>
  Оценить
</button>
<button
  *ngIf="package?.isCustomizable && dish?.shouldBeConfirmed && !dish?.isReplaced && dish?.isAdded"
  class="text-btn"
  [ngClass]="{'black-button': isFromDetailsModal}"
  (click)="deselect(dish, package?.packageId)"
>
  Отменить
</button>

<ion-modal [isOpen]="isModalOpen" (willDismiss)="onWillDismiss($event)">
  <ng-template>
    <app-top-toolbar title="Замена для блюда" (goBack)="closeModal()"></app-top-toolbar>
    <div class="modal-sorting">
      <app-icon icon="sort" class="sort-icon" (click)="showSortDropdown()"></app-icon>
      <div class="dropdown header-dropdown-menu" [class.shown]="isSortingDropdownShown$ | async">
        <p class="dropdown-title">Как сортировать блюда?</p>
        <div class="dropdown-options">
          <app-radio-toggler
            class="save-card-toggle"
            [value]="filterMethod === 'filterByCcal'"
            (changeValue)="selectFilterMethod('filterByCcal')"
            >По разнице ккал</app-radio-toggler
          >
          <app-radio-toggler
            class="save-card-toggle"
            [value]="filterMethod === 'filterByTitle'"
            (changeValue)="selectFilterMethod('filterByTitle')"
            >По алфавиту</app-radio-toggler
          >
          <app-radio-toggler
            class="save-card-toggle"
            [value]="filterMethod === 'filterByPrice'"
            (changeValue)="selectFilterMethod('filterByPrice')"
            >По цене</app-radio-toggler
          >
        </div>

        <button class="btn sort-btn" (click)="sortDishes()">Показать</button>
      </div>
    </div>

    <ion-content class="ion-padding">
      <div class="old-dish">
        <div class="old-dish-info">{{ dish.grams | customNumber: 'weight'}} г/ {{ dish.caloricity | customNumber: 'calories' }} ккал</div>
        <div class="old-dish-title">{{dish.title}}</div>
      </div>

      <div class="loading" *ngIf="isLoading$ | async; else contentBlock">
        <div class="loading-content">
          <p class="msg text--18">Загружаем блюда</p>
          <div class="dot-flashing"></div>
        </div>
      </div>

      <ng-template #contentBlock>
        <ng-container *ngIf="allDishes?.length; else noResultsBlock">
          <div class="meal-type-tabs">
            <div
              class="item"
              [ngClass]="{ active: activeMealTypeTab === category.key }"
              *ngFor="let category of dishesCategories | keyvalue: $any(sortByMealTypeFn); trackBy: trackByFn"
              (click)="scrollToCategory(category.key)"
            >
              {{ RuDishTypesEnum[category.key] }}
            </div>
          </div>

          <div class="dishes-list" #dishesListRef>
            <div
              class="row dish-row"
              *ngFor="let category of dishesCategories | keyvalue: $any(sortByMealTypeFn); trackBy: trackByFn"
              [ngClass]="category.key"
              [id]="category.key"
              appVisibilityObserver
              (visibilityChange)="setActiveCategory($event, category.key)"
            >
              <ng-container *ngTemplateOutlet="categoryTemplate; context: { title: category.key, dishes: category.value }"> </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-template #noResultsBlock>
          <div class="empty-list">
            <p class="msg text--18">Нет доступных блюд</p>
          </div>
        </ng-template>
      </ng-template>
    </ion-content>
  </ng-template>
  <ng-template #categoryTemplate let-title="title" let-dishes="dishes">
    <div class="meal-type-col">
      <p class="text--16 meal-type-title">{{ RuDishTypesEnum[title] }}</p>
    </div>

    <div class="list">
      <ng-container *ngFor="let dish of dishes">
        <app-dish-item-replaced
          [dish]="dish"
          [package]="package"
          (selectDish)="onSelectDish($event)"
          [isFromSelectDishModal]="true"
        ></app-dish-item-replaced>
      </ng-container>
    </div>
  </ng-template>
</ion-modal>
